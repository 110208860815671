import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

const FoodGroupContainer = styled.div`
visibility: inherit;opacity: 1;
transform: matrix(1, 0, 0, 1, 0, 0)
;align-items: flex-start;
position: relative;
top: 10em;
padding-left: 0;
padding-right: 15px;
position: -webkit-sticky;
position: sticky;
`

class MenuBlock extends PureComponent { 
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  render () {
    const { data } = this.props;
    const {
        restaurantImageTitle,
        restaurantImageSubTitle,
        restaurantImageDescription,
        foodMenu,
        cocktailMenu,
        beerMenu,
        wineMenu,
    } = data.markdownRemark.frontmatter.restaurantImages;
      let _foodMenu = !!foodMenu ? foodMenu.publicURL : "#";
      let _cocktailMenu = !!cocktailMenu ? cocktailMenu.publicURL : "#";
      let _beerMenu = !!beerMenu ? beerMenu.publicURL : "#";
      let _wineMenu = !!wineMenu ? wineMenu.publicURL : "#";


    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return (
      <div className="MenuBlockWrapper">
            <div id="Menu_sectional" style={{backgroundColor: '#f3f5f7'}}>
        <div className="container">
            <div className="row" id="menu_rows">
                <div className="col" id="pdf_columns_handler">
                    <div style={{textAlign: 'center'}}><img alt="Meal Menu" id="Food_menu_img" src={_foodMenu} height="100%" width="85%"/></div>
                </div>
                <div className="col">
                    <FoodGroupContainer>
                        <h1 id="header_one_black">{restaurantImageTitle}</h1>
                        <h1 id="header_one_gray">{restaurantImageSubTitle}</h1>
                        <p  id="paragraph_black">{restaurantImageDescription}<br/></p>
                        <a
                            id="link_black" target="_blank" rel="noopener noreferrer" href={foodMenu}>See the full food menu</a>
                            <div id="list_container_items">
                                <ul className="list-unstyled">
                                    <li id="food_items_class"><i className="icon-magic-wand" style={{color: '#003adb', paddingRight: '10px'}}/>&nbsp;Maine Lobster Roll</li>
                                    <li id="food_items_class"><i className="icon-fire" style={{color: '#003adb', paddingRight: '10px'}}/>&nbsp;Crispy Fried Artichokes</li>
                                    <li id="food_items_class"><i className="fas fa-lemon" style={{color: '#003adb', paddingRight: '10px'}}/>&nbsp;Baja</li>
                                </ul>
                            </div>
                    </FoodGroupContainer>
                </div>
            </div>
            <div className="row" id="menu_rows">
                <div className="col">
                    <FoodGroupContainer>
                        <h1 data-aos="fade-up" data-aos-duration="500" id="header_one_black">Our Beer + Cider</h1>
                        <h1 data-aos="fade-up" data-aos-duration="750" id="header_one_gray">Front row access to the action</h1>
                        <p data-aos="fade-up" data-aos-duration="1000" id="paragraph_black">We are &nbsp;a casual East Village neighborhood spot with heart and soul, serving seasonal American food in a welcoming, relaxed setting. Opened by Jake and his team in 2016, Brix+Brine was created to serve as an all-day gathering
                            place for neighbors and visitors alike. The restaurant is open monday thru saturday from 11 to 11. It's<br/>that simple.<br/></p><a id="link_black" target="_blank" rel="noopener noreferrer" href={beerMenu}>See the full beer &amp; cider menu</a>
                        <div id="list_container_items">
                            <ul className="list-unstyled">
                                <li id="food_items_class"><i className="fas fa-star-half" style={{color: '#003adb', paddingRight: '10px'}}/>&nbsp;Switchback Ale</li>
                                <li id="food_items_class"><i className="fas fa-road" style={{color: '#003adb', paddingRight: '10px'}}/>&nbsp;Great divide</li>
                                <li id="food_items_class"><i className="fab fa-cloudsmith" style={{color: '#003adb', paddingRight: '10px'}}/>&nbsp;Lost Nation</li>
                            </ul>
                        </div>
                    </FoodGroupContainer>
                </div>
                <div className="col" id="pdf_columns_handler">
                    <div style={{textAlign: 'center'}}><img alt="Beer Menu" src={_beerMenu} height="100%" width="85%" style={{borderWidth: '10px', borderColor: 'white'}}/></div>
                </div>
            </div>
            <div className="row" id="menu_rows">
                <div className="col" id="pdf_columns_handler">
                    <div style={{textAlign: 'center'}}><img alt="Wine Menu" src={_wineMenu} height="100%" width="85%" style={{borderWidth: '10px', borderColor: 'white'}}/></div>
                </div>
                <div className="col">
                    <FoodGroupContainer>
                        <h1 data-aos="fade-up" data-aos-duration="500" id="header_one_black">Our Wine</h1>
                        <h1 data-aos="fade-up" data-aos-duration="750" id="header_one_gray">Front row access to the action</h1>
                        <p data-aos="fade-up" data-aos-duration="1000" id="paragraph_black">Beautiful locations typically have great tasting wine that gather the highlights of every culture in one place: here are some of our guests favorites.<br/></p><a id="link_black"  target="_blank" rel="noopener noreferrer" href={wineMenu}>See the full wine menu</a>
                        <div id="list_container_items">
                            <ul className="list-unstyled">
                                <li id="food_items_class"><i className="icon-magic-wand"  style={{color: '#003adb', paddingRight: '10px'}}/>&nbsp;Grape</li>
                                <li id="food_items_class"><i className="icon-fire"  style={{color: '#003adb', paddingRight: '10px'}}/>Chardonnay</li>
                                <li id="food_items_class"><i className="fas fa-lemon" style={{color: '#003adb', paddingRight: '10px'}}/>&nbsp;Cinsault</li>
                            </ul>
                        </div>
                    </FoodGroupContainer>
                </div>
            </div>
            <div className="row" id="menu_rows" style={{paddingBottom: '10vw'}}>
                <div className="col">
                    <FoodGroupContainer>
                        <h1 data-aos="fade-up" data-aos-duration="500" id="header_one_black">Our Cocktails</h1>
                        <h1 data-aos="fade-up" data-aos-duration="750" id="header_one_gray">Front row access to the action</h1>
                        <p data-aos="fade-up" data-aos-duration="1000" id="paragraph_black">We are &nbsp;a casual East Village neighborhood spot with heart and soul, serving seasonal American food in a welcoming, relaxed setting. Opened by Jake and his team in 2016, Brix+Brine was created to serve as an all-day gathering
                            place for neighbors and visitors alike. The restaurant is open monday thru saturday from 11 to 11. It's<br/>that simple.<br/></p><a id="link_black" target="_blank" rel="noopener noreferrer" href={cocktailMenu}>See the full cocktail menu</a>
                        <div id="list_container_items">
                            <ul className="list-unstyled">
                                <li id="food_items_class"><i className="fas fa-glass-martini-alt"  style={{color: '#003adb', paddingRight: '10px'}}/>&nbsp; The Jenks</li>
                                <li id="food_items_class"><i className="fas fa-apple-alt"  style={{color: '#003adb', paddingRight: '10px'}}/>&nbsp; 9481</li>
                                <li id="food_items_class"><i className="fas fa-umbrella-beach"  style={{color: '#003adb', paddingRight: '10px'}}/>&nbsp;Little Beach</li>
                            </ul>
                        </div>
                    </FoodGroupContainer>
                </div>
                <div className="col" id="pdf_columns_handler">
                    <div style={{textAlign: 'center'}}><img alt="Cocktail Menu" src={_cocktailMenu} height="100%" width="85%" style={{borderWidth: '10px', borderColor: 'white'}}/></div>
                </div>
            </div>
        </div>
    </div>


      </div>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "blank-template" } }) {
          frontmatter {
              restaurantImages {
                restaurantImageTitle
                restaurantImageSubTitle
                restaurantImageDescription
                beerMenu {
                    publicURL
                }
                foodMenu {
                    publicURL
                }
                wineMenu {
                    publicURL
                }
                cocktailMenu {
                    publicURL
                }
              } 
          }
        }
      }
    `}
    render={data => <MenuBlock data={data} {...props} />}
  />
);