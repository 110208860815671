import React from "react";
import logo from "../img/logo.svg";
import styled from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { StaticQuery, graphql } from "gatsby";

const NavbarBrand = styled.a`
font-weight: 400;
color: #fafafa;
background-image: url(${logo});
background-position: center;height: 55px;width: 92px;background-size: contain;background-repeat: no-repeat;
`;

const NavbarOutline = styled.nav`
background-color: rgba(254,254,254,0);
position: absolute;
width: 100%;
z-index: 20;
`;

const LogoHolder = styled.span`
background-image: url('../img/food_menu.svg');
`

const DesktopContainer = styled.div``

const MobileContainer = styled.div`
  display: block;
  height: ${props => props.active ? 100 : 0};
  width: 100%;
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  background-color: white;
  overflow-y: hidden;
  transition: 0.5s;
`

const MobileClosureLink = styled.a`
color: #1f1f1f;font-weight: 300;font-size: 3.0em;width: 100%;text-decoration: none;cursor:grab;
`

const MobileLinks = styled(AnchorLink)`
color: #9ea0a6;font-weight: 300;font-size: 2.0em;text-decoration: none;
`

const ExternalMobileLink = styled.a`
  color: #9ea0a6;font-weight: 300;font-size: 2.0em;text-decoration: none;
`

const MobileLinksContainer = styled.div`
  padding: 25px 0;
  width: 100%;
`

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: ""
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active"
            })
          : this.setState({
              navBarActiveClass: ""
            });
      }
    );
  };

  render() {
      const { data } = this.props;
      const {
          facebook,
          instagram,
      } = data.markdownRemark.frontmatter.socialLinks;

    return (
        <NavbarOutline className="navbar navbar-light navbar-expand-md navigation-clean-button">
            <div className="container">
                <NavbarBrand className="navbar-brand" href="/">
                    <br/><span style={{height: '40px', width: '92px'}}/>
                </NavbarBrand>
                <button data-toggle="collapse" onClick={() => this.toggleHamburger()} className="navbar-toggler" id="nav_menubar1" style={{borderColor: 'transparent'}}>
                    <LogoHolder className="navbar-toggler-icon"/><span className="sr-only">Toggle navigation</span>
                </button>
                {this.state.active ?
                    <MobileContainer id="menu_items_overlay" onClick={() => this.toggleHamburger()} active={this.state.active}>
                        <div style={{height: "100vh"}}>
                            <div className="container" style={{paddingTop: "50px", paddingLeft: "20px"}}>
                                <MobileLinksContainer className="row" style={{padding: "25px 0"}}>
                                    <div className="col-md-12" style={{textAlign: "right"}}>
                                        <MobileClosureLink
                                        onClick={() => this.toggleHamburger()}><i
                                        className="fas fa-times" style={{fontSize:" 0.8em",
                                            color:" #a5a9ad"}}/>
                                        </MobileClosureLink>
                                    </div>
                                </MobileLinksContainer>
                                <MobileLinksContainer className="row">
                                    <div className="col-md-12">
                                        <MobileLinks href="/">
                                            Home
                                        </MobileLinks>
                                    </div>
                                </MobileLinksContainer>
                                <MobileLinksContainer className="row">
                                    <div className="col-md-12">
                                        <MobileLinks href="#Gallery_sectional">
                                            Gallery
                                        </MobileLinks>
                                    </div>
                                </MobileLinksContainer>
                                <MobileLinksContainer className="row">
                                    <div className="col-md-12">
                                        <MobileLinks href="#Menushowcase">
                                            Menus
                                        </MobileLinks>
                                    </div>
                                </MobileLinksContainer>
                                <MobileLinksContainer className="row">
                                    <div className="col-md-12">
                                        <MobileLinks href="#Contact_sectional">
                                            Contact
                                        </MobileLinks>
                                    </div>
                                </MobileLinksContainer>
                                <MobileLinksContainer className="row">
                                    <div className="col-md-12">
                                        <MobileLinks href={instagram}><i
                                        className="fab fa-instagram"/>
                                        </MobileLinks>
                                        <MobileLinks href={facebook}
                                                     style={{paddingLeft: "20px"}}><i
                                        className="fab fa-facebook"/>
                                        </MobileLinks>
                                        <ExternalMobileLink href="//app.upserve.com/s/brix-brine-ogunquit">
                                            Order Now
                                        </ExternalMobileLink>
                                    </div>
                                </MobileLinksContainer>
                            </div>
                        </div>
                    </MobileContainer>
                    :
                    <DesktopContainer
                        className="collapse navbar-collapse" id="navcol-1">
                        <ul className="nav navbar-nav ml-auto" style={{color: '#222222'}}>
                            <li className="nav-item" role="presentation" style={{fontWeight: '300'}}><a className="nav-link" href="/">Home</a></li>
                            <li className="nav-item" role="presentation" style={{fontWeight: '300'}}><AnchorLink className="nav-link" href="#Gallery_sectional">Gallery</AnchorLink></li>
                            <li className="nav-item" role="presentation" style={{fontWeight: '300'}}><AnchorLink className="nav-link" href="#Menushowcase">Menus</AnchorLink></li>
                            <li className="nav-item" role="presentation" style={{fontWeight: '300'}}><a className="nav-link" href="//app.upserve.com/s/brix-brine-ogunquit">Order Now</a></li>
                            <li className="nav-item" role="presentation" style={{fontWeight: '300'}}><AnchorLink className="nav-link" href="#Contact_sectional">Contact</AnchorLink></li>
                            <li className="nav-item" role="presentation"><a className="nav-link login" href={instagram}><i className="fab fa-instagram"/></a></li>
                            <li className="nav-item" role="presentation"><a className="nav-link login" href={facebook}><i className="fab fa-facebook"/></a></li>
                        </ul>
                    </DesktopContainer>
                }
            </div>
        </NavbarOutline>
    );
  }
};

export default props => (
    <StaticQuery
        query={graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "blank-template" } }) {
          frontmatter {
              socialLinks {
                facebook
                instagram
              }
          }
        }
      }
    `}
        render={data => <Navbar data={data} {...props} />}
    />
);
