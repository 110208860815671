import React, { PureComponent } from "react";
import { StaticQuery, graphql } from "gatsby";
import { IntroImageContainer } from "./HeaderComponentContainer.styles";

class HeaderComponentContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  componentDidMount = () => {
  };

  render() {
    const { data, headerVideo } = this.props;
      const {
          imageIntro,
          video,
      } = data.markdownRemark.frontmatter.introduction;

    //const regexStr = "https://www.youtube.com/embed/[A-Za-z0-9-]*"; // this removes the 'no controls' part of the url
    const regexStr = 'https://www.youtube.com/embed/.+?(?="|$)'; // this receives the whole url
    const regex = new RegExp(regexStr);
    let obj = regex.exec(video);

    const videoContainer = (
        <div style={{ paddingTop: "56.25%" }}>
          <iframe
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: "0",
                left: "0",
              }}
              src={obj}
              frameBorder="0"
              title="Youtube Video"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
          />
        </div>
    );

    const imageContainer = (
        <IntroImageContainer data-aos="fade-up" data-aos-delay="250">
            <div className="row" style={{marginBottom: "-135px"}}>
                <div className="col" style={{width: "1140px", height: "624px", overflow: "hidden"}}>
                    <img src={imageIntro.publicURL} id="container_images_compoenent" alt="Dining Room" width="100%"/>
                </div>
            </div>
        </IntroImageContainer>
    );



    if (this.state.hasError) {
      return <h1>Something went very wrong.</h1>;
    }
    return (
      <div className="HeaderComponentContainerWrapper">
        <div id="header_componenent_container">
          <div className="container">
            <div className="row">
              <div className="col-md-12" style={{ position: "relative" }} data-aos="fade-up">
                {headerVideo ? videoContainer : imageContainer}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "blank-template" } }) {
          frontmatter {
          introduction {
            video
            imageIntro {
                publicURL
            }
          }

          }
        }
      }
    `}
    render={(data) => <HeaderComponentContainer data={data} {...props} />}
  />
);
