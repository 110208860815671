import React, { PureComponent } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { MenuBlockContainer, MenuBlockGroup, MenuBlockText} from "./MenuHeader.styles";

class MenuHeader extends PureComponent { 
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  render () {
      if (this.state.hasError) {
          return <h1>Something went wrong.</h1>;
      }

      const { data } = this.props;
      const {
          restaurantImageTitle,
          restaurantImageDescription,
          foodMenu,
          cocktailMenu,
          beerMenu,
          wineMenu,
      } = data.markdownRemark.frontmatter.restaurantImages;
      let fMenu = !!foodMenu ? foodMenu.publicURL : "#";
      let cMenu = !!cocktailMenu ? cocktailMenu.publicURL : "#";
      let bMenu = !!beerMenu ? beerMenu.publicURL : "#";
      let wMenu = !!wineMenu ? wineMenu.publicURL : "#";

      return (
        <div id="Menushowcase" style={{padding: "135px 0"}}>
            <div className="container" style={{maxWidth: "min-content"}}>
                <div className="row" style={{paddingBottom: "135px"}}>
                    <div className="col-md-6">
                        <div>
                            <h1 data-aos="fade-up" data-aos-duration="500" id="header_one_white"
                                style={{color: "#1f1f1f"}}>{restaurantImageTitle}<br/></h1>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <p data-aos="fade" data-aos-delay="200" id="paragraph_black" style={{fontSize: "22px"}}>{restaurantImageDescription}</p>
                    </div>
                </div>
                <div className="row" style={{textAlign: "center"}}>
                    <MenuBlockContainer className="col-lg-12">
                        <div id="FoodMenuButtons" data-aos="fade-up">
                            <MenuBlockGroup target="_blank" rel="noopener noreferrer" href={fMenu}>
                                <MenuBlockText>Food</MenuBlockText>
                            </MenuBlockGroup>
                        </div>
                        <div id="FoodMenuButtons" data-aos="fade-up" data-aos-delay="100">
                            <MenuBlockGroup target="_blank" rel="noopener noreferrer" href={wMenu}>
                                <MenuBlockText>Wine</MenuBlockText>
                            </MenuBlockGroup>
                        </div>
                        <div id="FoodMenuButtons" data-aos="fade-up" data-aos-delay="200">
                            <MenuBlockGroup target="_blank" rel="noopener noreferrer" href={bMenu}>
                                <MenuBlockText>Beer</MenuBlockText>
                            </MenuBlockGroup>
                        </div>
                        <div id="FoodMenuButtons" data-aos="fade-up" data-aos-delay="300">
                            <MenuBlockGroup target="_blank" rel="noopener noreferrer" href={cMenu}>
                                <MenuBlockText>Cocktails</MenuBlockText>
                            </MenuBlockGroup>
                        </div>
                    </MenuBlockContainer>
                </div>
            </div>
        </div>
    );
  }
}

export default props => (
    <StaticQuery
        query={graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "blank-template" } }) {
          frontmatter {
              restaurantImages {
                restaurantImageTitle
                restaurantImageDescription
                beerMenu {
                    publicURL
                }
                foodMenu {
                    publicURL
                }
                wineMenu {
                    publicURL
                }
                cocktailMenu {
                    publicURL
                }
              } 
          }
        }
      }
    `}
        render={data => <MenuHeader data={data} {...props} />}
    />
);