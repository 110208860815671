import React, { PureComponent } from "react";
import { IntroMantra, ContainerComp, Chevron, LinkHandler } from  "./HeaderComponent.styles.js"


import {graphql, StaticQuery} from "gatsby";

class HeaderComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false
    };
  }

  render() {
    const {data} = this.props;
    const {
      calloutToggle,
      introCallOut,
      introMantra
    } = data.markdownRemark.frontmatter.introduction;

    if (this.state.hasError) {
      return (
        <h1><span role="img" aria-label="shrug">🤷</span></h1>
      );
    }
    return (
      <div className="HeaderComponentWrapper">
        <div id="Header_Component" style={{ backgroundColor: "white" }}>
          <ContainerComp className="container">
            <div className="row">
              <div className="col-md-12">
                { calloutToggle ? <h1 data-aos="fade-up" data-aos-duration="1000" id="header_sectional_heading">
                      {introCallOut}
                  <br />
                </h1> :
                    <IntroMantra data-aos="fade-up" data-aos-duration="1000">
                      <strong style={{color: "#003adb"}}>{introMantra}</strong><br />
                    </IntroMantra>
                }
              </div>
            </div>
            <div className="row" style={{ paddingBottom: "5vw" }}>
              <div
                className="col-md-12"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <LinkHandler
                  className="btn btn-primary"
                  role="button"
                  href="#showcase"
                  offset={() => 150}
                >
                  <Chevron className="fa fa-chevron-down"/>
                </LinkHandler>
              </div>
            </div>
          </ContainerComp>
        </div>
      </div>
    );
  }
}


export default (props) => (
    <StaticQuery
        query={graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "blank-template" } }) {
          frontmatter {
            introduction {
                calloutToggle
                introCallOut
                introMantra
            }
          }
        }
      }
    `}
        render={data => <HeaderComponent data={data} {...props} />}
    />
);
