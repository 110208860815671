import React, { PureComponent } from 'react';
import styled from "styled-components";
import logo from '../../img/logo.svg'

const PreloaderContainer = styled.div`
    bottom: 0;
    display: flex;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
    background-color: #FDFDFD;
    justify-content: center;
    align-items: center;
    -webkit-animation: slide-out-fwd-center 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) 2s both;
    animation: slide-out-fwd-center 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) 2s both;
`

const PreloaderGroup = styled.div`
  text-align: center;
    -webkit-animation: slide-out-fwd-center 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.7s both;
    animation: slide-out-fwd-center 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.7s both;
`

class JQueryImports extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      isLoading: true
    };
  }

  componentDidMount = () => {
    const isBrowser = typeof window !== 'undefined';
    const AOS = isBrowser ? require('aos') : undefined;
    this.asos = AOS;
    this.asos.init();
    this.loader()
  }

  async loader() {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    await delay(2700);
    this.setStatus()
  }

  setStatus() {
    this.setState({isLoading: false})
  }

  componentDidUpdate = () => {
    this.asos.refresh();
  }

  render () {
    return (
        <div>
          {
            this.state.isLoading ? <PreloaderContainer>
                  <PreloaderGroup>
                    <img src={logo} alt="brix+brine" height="50px"/>
                    <div className="bounce-top" style={{marginTop: "calc(2vw + 5px)"}}>
                      Loading
                    </div>
                  </PreloaderGroup>
                </PreloaderContainer>
             : null
          }
        </div>
    );
  }
}

export default JQueryImports;