import styled from "styled-components";

export const MenuBlockContainer = styled.div`
display: inline-flex;align-items: center;text-align: center;justify-content: center;
`
export const MenuBlockGroup = styled.a`
color: #003adb;text-decoration: initial;
`

export const MenuBlockText = styled.p`
font-weight: 500;margin: 15px;max-width: 250px;
`