import React, {PureComponent} from 'react';
import {graphql, StaticQuery} from "gatsby";
import { SpotlightContainer, RightPaddedImage, LeftPaddedImage, SpotlightImageContainer, SpotlightImageGroup }
from './GalleryBlock.styles.js'

class GalleryBlock extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
        };
    }

    render() {
        const {data} = this.props;
        const {
            galleryTitle,
            gallerySubTitle,
            galleryDescription,
            ig1i1,
            ig1i2,
            ig1S,
            ig2i1,
            ig2i2,
            ig2S,
        } = data.markdownRemark.frontmatter.galleryImages;
        let gallery_Image1 = !!ig1i1 ? ig1i1.publicURL : "#";
        let gallery_Image2 = !!ig1i2 ? ig1i2.publicURL : "#";
        let gallery_Image3 = !!ig1S ? ig1S.publicURL : "#";
        let gallery_Image4 = !!ig2i1 ? ig2i1.publicURL : "#";
        let gallery_Image5 = !!ig2i2 ? ig2i2.publicURL : "#";
        let gallery_Image6 = !!ig2S ? ig2S.publicURL : "#";



        if (this.state.hasError) {
            return <h1>Something went wrong.</h1>;
        }
        return (
            <div className="GalleryBlockWrapper">
                <div id="Gallery_sectional" style={{backgroundColor: "#16171c", height: "auto"}}>
                    <div className="container" style={{padding: "10vw 0"}}>
                        <div className="row">
                            <div className="col-md-6">
                                <div style={{fontWeight: "300", padding: "30px 15px"}}>
                                    <h1 data-aos="fade-up" data-aos-duration="250" id="header_one_white">{galleryTitle}
                                        <br/>
                                    </h1>
                                    <h1 data-aos="fade-up" data-aos-duration="500" id="header_one_gray">{gallerySubTitle}<br/>
                                    </h1>
                                    <p data-aos="fade-up" data-aos-duration="750" id="paragraph_white">{galleryDescription}<br/></p>
                                </div>
                            </div>
                            <div className="col-md-6"/>
                        </div>
                        <div className="row" style={{paddingTop: "10vw"}}>
                            <LeftPaddedImage className="col"><img alt="gallery asset 1" data-aos="fade-up"
                                                                                                  data-aos-duration="750"
                                                                                                  id="container_images_compoenent"
                                                                                                  src={gallery_Image1}
                                                                                                  width="100%"/></LeftPaddedImage>
                            <RightPaddedImage className="col"><img alt="gallery asset 2" data-aos="fade-up"
                                                                                                 data-aos-duration="750"
                                                                                                 id="container_images_compoenent"
                                                                                                 src={gallery_Image2}
                                                                                                 width="100%"/></RightPaddedImage>
                        </div>
                        <SpotlightContainer className="row">
                            <SpotlightImageGroup className="col">
                                <SpotlightImageContainer><img alt="gallery asset 3" data-aos="fade-up"
                                                                                     data-aos-duration="1000"
                                                                                     id="container_images_compoenent"
                                                                                     src={gallery_Image3}
                                                                                     width="100%"/></SpotlightImageContainer>
                            </SpotlightImageGroup>
                        </SpotlightContainer>
                        <div className="row">
                            <LeftPaddedImage className="col"><img alt="gallery asset 4" data-aos="fade-up"
                                                                                                  data-aos-duration="750"
                                                                                                  id="container_images_compoenent"
                                                                                                  src={gallery_Image4}
                                                                                                  width="100%"/></LeftPaddedImage>
                            <RightPaddedImage className="col"><img alt="gallery asset 5" data-aos="fade-up"
                                                                                                 data-aos-duration="750"
                                                                                                 id="container_images_compoenent"
                                                                                                 src={gallery_Image5}
                                                                                                 width="100%"/></RightPaddedImage>
                        </div>
                        <SpotlightContainer className="row">
                            <SpotlightImageGroup className="col">
                                <SpotlightImageContainer><img alt="gallery asset 6" data-aos="fade-up"
                                                                                     data-aos-duration="1000"
                                                                                     id="container_images_compoenent"
                                                                                     src={gallery_Image6}
                                                                                     width="100%"/></SpotlightImageContainer>
                            </SpotlightImageGroup>
                        </SpotlightContainer>
                    </div>
                </div>
            </div>
        );
    }
}

export default props => (
    <StaticQuery
        query={graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "blank-template" } }) {
          frontmatter {
              galleryImages {
                galleryTitle
                gallerySubTitle
                galleryDescription
                ig1i1 {
                    publicURL
                }
                ig1i2 {
                    publicURL
                }
                ig1S {
                    publicURL
                }
                ig2i1 {
                    publicURL
                }
                ig2i2 {
                    publicURL
                }
                 ig2S {
                    publicURL
                }
              } 
          }
        }
      }
    `}
        render={data => <GalleryBlock data={data} {...props} />}
    />
);

