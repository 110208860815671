import React from "react";
import ContactBlock from './ContactBlock';
import {graphql, StaticQuery} from "gatsby";

export default props => (
    <StaticQuery
        query={graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "blank-template" } }) {
          frontmatter {
              contactBlockSection {
                contactUsTitle
                contactUsSubTitle
                contactUsSecondaryTitle
                contactUsSecondarySubTitle 
                contactUsSecondaryEmail
                contactUsImage {
                    publicURL
                }
              } 
          }
        }
      }
    `}
        render={data => <ContactBlock data={data} {...props} />}
    />
);