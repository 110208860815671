import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from './Footer';
import Navbar from './Navbar';
import RestaurantBlock from './ResturantBlock';
import IntroAnnouncementContainer from "./IntroAnnouncementContainer/IntroAnnouncementContainer";
import ContactBlock from './ContactBlock';
import MapBlock from './MapBlock/MapBlock';
import MenuBlock from './MenuBlock/MenuBlock';
import StoryBlock from './StoryBlock/StoryBlock';
import MenuHeader from './MenuHeader/MenuHeader';
import GalleryBlock from './GalleryBlock/GalleryBlock';
import RawBlock from './RawBarBlock/RawBarBlock';
import BarHeader from './BarHeader/BarHeader';
import HeaderComponentContainer from './HeaderComponentContainer/HeaderComponentContainer';
import HeaderComponent from './HeaderComponent/HeaderComponent';
import JQueryImports from './AsosHandler';
import { useStaticQuery, graphql } from "gatsby";

import useSiteMetadata from './SiteMetadata';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import 'simple-line-icons/css/simple-line-icons.css';
import '../css/styles.css';
import '../css/bootstrap.min.css';
import 'aos/dist/aos.css';

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata()

    const { markdownRemark } = useStaticQuery(graphql`
        query ToggleQuery {
            markdownRemark(frontmatter: { templateKey: { eq: "blank-template" } }) {
                frontmatter {
                    visibilityToggles {
                        announcementBanner
                        headerVideo
                        barHeader
                        contactBlock
                        galleryBlock
                        headerComponent
                        headerComponentContainer
                        mapBlock
                        rawBlock
                        menuHeader
                        menuBlock
                        restaurantBlock
                        storyBlock
                    }
                }
            }
        }
    `);

    const {
        announcementBanner,
        headerVideo,
        barHeader,
        contactBlock,
        galleryBlock,
        headerComponent,
        headerComponentContainer,
        mapBlock,
        rawBlock,
        menuHeader,
        menuBlock,
        restaurantBlock,
        storyBlock,
    } = markdownRemark.frontmatter.visibilityToggles;


  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="theme-color" content="#ffffff"/>
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content="../img/favicon.png" />
        <link href="//fonts.googleapis.com/css2?family=Domine:wght@400;700&family=Karla:wght@400;700&family=Merriweather:wght@300;700&family=Rubik:wght@300;400;500;700&display=swap" rel="stylesheet"/>
        <script src='https://app.upserve.com/platform/olo-widget.js?brix-brine-ogunquit' type='text/javascript' id='upserve-olo-script'/>
      </Helmet>
      <JQueryImports/>
        {announcementBanner ? <IntroAnnouncementContainer/> : null}
      <Navbar />
        {headerComponent ? <HeaderComponent /> : null}
        {headerComponentContainer ? <HeaderComponentContainer headerVideo={headerVideo} /> : null}
        {barHeader ? <BarHeader /> : null}
        {rawBlock ? <RawBlock /> : null}
        {galleryBlock ? <GalleryBlock /> : null}
        {menuHeader ? <MenuHeader /> : null}
        {menuBlock ? <MenuBlock /> : null}
        {storyBlock ? <StoryBlock /> : null}
        {mapBlock ? <MapBlock /> : null}
        {contactBlock ? <ContactBlock /> : null}
        {restaurantBlock ? <RestaurantBlock /> : null}
      <Footer />
    </div>
  )
}

export default TemplateWrapper
