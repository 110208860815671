import React, {PureComponent} from "react";
import styled from "styled-components";
import logo from "../img/brixbrine_white.png";
import { StaticQuery, graphql } from "gatsby";
import AnchorLink from "react-anchor-link-smooth-scroll";

// Styled Components For CSS
const FooterComponent = styled.div``;

const FooterSocialContainer = styled.div`
justify-content: flex-end;height: 100px;display: flex;line-height: 100px;white-space: nowrap;
`

const FooterWebsiteContainer = styled.div`
height: 100px;line-height: 100px;white-space: nowrap;
`

const FooterWebsiteText = styled.a`
text-decoration: none;color: #fafafa;
`

const FooterStyledListContainer = styled.ul`
color: #FAFAFA;font-family: 'PT Serif', serif;font-weight: 300;
`

const FooterHeadingsContainer = styled.h5`
color: #fafafa;font-family: Poppins, sans-serif;letter-spacing: .1em;
`
const FooterLinks = styled(AnchorLink)`
color: #FAFAFA;font-family: 'PT Serif', serif;font-weight: 300;text-decoration: none;
`

const StandardFooterLink = styled.a`
color: #FAFAFA;font-family: 'PT Serif', serif;font-weight: 300;text-decoration: none;

`

class Footer extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
        };
    }

    render() {
        const { data } = this.props;
        const {
            sunHours,
            monSatHours,
            addressRoad,
            addressCityAndState,
            phoneNumber,
            emailAddress,
        } = data.markdownRemark.frontmatter.footerSection;

        const {
            facebook,
            instagram,
            brixbrine,
        } = data.markdownRemark.frontmatter.socialLinks;

        const hoursSection = (
            <div className="col-md-3">
                <div>
                    <FooterHeadingsContainer>
                        Hours
                    </FooterHeadingsContainer>
                    <div>
                        <FooterStyledListContainer
                            className="list-unstyled">
                            <li>Sun: {sunHours}</li>
                            <li>Mon-Sat: {monSatHours}</li>
                        </FooterStyledListContainer>
                    </div>
                </div>
            </div>
        );

        return (
            <FooterComponent id="footer-component">
                <div className="container">
                    <div className="row" style={{padding: "100px 0"}}>
                        <div className="col-md-3">
                            <div><a href="#Header_Component"><img src={logo} alt="logo" width="75%"/></a></div>
                        </div>
                        {hoursSection}
                        <div className="col-md-3">
                            <div>
                                <FooterHeadingsContainer>Restaurant</FooterHeadingsContainer>
                                <div>
                                    <FooterStyledListContainer className="list-unstyled">
                                        <li><FooterLinks href="#Menushowcase">Menus</FooterLinks>
                                        </li>
                                        <li><FooterLinks href="#story_block">About</FooterLinks>
                                        </li>
                                        <li><StandardFooterLink href="//goo.gl/maps/72GEMpVTFa2GvK8H6">Directions</StandardFooterLink>
                                        </li>
                                        <li><StandardFooterLink href="//app.upserve.com/s/brix-brine-ogunquit">Order Now</StandardFooterLink>
                                        </li>
                                        <li><StandardFooterLink href="//app.upserve.com/b/brix-brine/gift-card">Gift Cards</StandardFooterLink>
                                        </li>
                                    </FooterStyledListContainer>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div>
                                <FooterHeadingsContainer>Contact</FooterHeadingsContainer>
                                <div>
                                    <FooterStyledListContainer className="list-unstyled">
                                        <li>{addressRoad}</li>
                                        <li>{addressCityAndState}</li>
                                        <li><i className="fa fa-phone"/> {phoneNumber}</li>
                                        <li><i className="fab fa-mailchimp"/> {emailAddress}</li>
                                    </FooterStyledListContainer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container" style={{borderTop: "1px solid rgba(142,147,150,0.12)"}}>
                    <div className="row">
                        <div className="col-md-6">
                            <FooterWebsiteContainer><FooterWebsiteText href={brixbrine}>@Brix+Brine,
                                LLC</FooterWebsiteText></FooterWebsiteContainer>
                        </div>
                        <div className="col-md-6">
                            <FooterSocialContainer>
                                <a href={instagram} style={{textDecoration: "none", color: "#fafafa", margin: "0 20px"}}><i className="fab fa-instagram"/></a>
                                <a href={facebook}
                                   style={{textDecoration: "none", color: "#fafafa", margin: "0 20px"}}><i className="fab fa-facebook-square"/></a>
                            </FooterSocialContainer>
                        </div>
                    </div>
                </div>
            </FooterComponent>
        );
    }
}

export default props => (
    <StaticQuery
        query={graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "blank-template" } }) {
          frontmatter {
              footerSection{
                sunHours
                monSatHours
                addressRoad
                addressCityAndState
                phoneNumber
                emailAddress
              }
              socialLinks {
                brixbrine
                facebook
                instagram
              }
          }
        }
      }
    `}
        render={data => <Footer data={data} {...props} />}
    />
);
