import styled from "styled-components";

export const BarHeaderContainer = styled.div`
height: 100vh, auto;z-index: 20;padding: 20px;background-color: #ffffff;
`;

export const TextGroup = styled.div`
font-family: Merriweather, serif;font-weight: 300;padding-top: 15px;bottom: 0px;
`;

export const ContainerView = styled.div`
      padding: 10vw 0;
    `

export const RawBarView = styled.div`
        height: auto;
        background-color: #232328;
`

export const RawbarGroup = styled.div`
visibility: inherit;opacity: 1;transform: matrix(1, 0, 0, 1, 0, 0);align-items: flex-start;position: relative;top: 10em;padding-left: 15px;padding-right: 15px;transform: translateZ(0);position: -webkit-sticky;position: sticky;
`

export const BarHeaderContainerV2 = styled.div`
`

export const ImageContainer = styled.div`
    display: block;
     @media (max-width: 768px) {
        display: none;
     }
`

export const HeaderOneGrayModification = styled.h1`
  @media (min-width: 1024px) {
    white-space: nowrap
  }
`

