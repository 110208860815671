import React, { PureComponent } from 'react';
import { BarHeaderContainer, BarHeaderContainerV2, ContainerView, RawbarGroup, RawBarView , TextGroup, ImageContainer, HeaderOneGrayModification }
from './BarHeader.styles.js';
import logo from '../../img/brixbrine_white.png'
import {graphql, StaticQuery} from "gatsby";



class BarHeader extends PureComponent { 
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }




  render () {
    let versionOneStyling = false;
    const {data} = this.props;
    const {
        secIntro,
        secSub,
        secInfo
      } = data.markdownRemark.frontmatter.secondaryIntroduction;

    if (this.state.hasError) {
      return <h1>Fatal Error</h1>;
    }

    const BarHeaderWrapperV1 = (
        <div className="BarHeaderWrapper">
            <BarHeaderContainer id="bar_header">
                <div className="container" style={{padding: '5vw 0px 10vw'}}>
                    <div className="row">
                        <div className="col"/>
                        <div className="col-md-6" data-aos="fade" style={{fontWeight: '300'}}>
                            <TextGroup>
                                <h1 data-aos="fade-up" data-aos-delay="250" id="video_component_heading">Changing the game</h1>
                            </TextGroup>
                            <p data-aos="fade-up" data-aos-delay="500" id="video_component_paragraph">In the approachable spirit of the brand, we kept the food down to earth, unstuffy and not overly staged.</p><a data-aos="fade-up" data-aos-delay="750" href="#showcase" style={{textDecoration: 'none', color: '#1f1f1f', fontWeight: '700'}}>Learn more&nbsp;<i className="fas fa-arrow-right" style={{fontSize: '10px'}}/></a></div>
                    </div>
                </div>
            </BarHeaderContainer>
        </div>
    );

    return (
        <BarHeaderContainerV2>
            { versionOneStyling ? BarHeaderWrapperV1 :
                <RawBarView id="raw_bar">
                    <ContainerView className="container">
                        <div className="row" id="showcase" style={{marginTop: "135px"}}>
                            <div className="col-md-6">
                                <ImageContainer style={{textAlign: "center"}}><img id="Menu_Showcase_img" src={logo}
                                                                      height="100%"
                                                                      width="85%" alt="logo"/></ImageContainer>
                            </div>
                            <div className="col-md-6">
                                <RawbarGroup>
                                    <h1 data-aos="fade-up" data-aos-duration="500" id="header_one_white">{secIntro}<br/></h1>
                                    <HeaderOneGrayModification data-aos="fade-up" data-aos-duration="750" id="header_one_gray">{secSub}</HeaderOneGrayModification>
                                    <p data-aos="fade-up" data-aos-duration="1000" id="paragraph_white">{secInfo}<br/></p>
                                </RawbarGroup>
                            </div>
                        </div>
                    </ContainerView>
                </RawBarView>
            }
        </BarHeaderContainerV2>
    );
  }
}


export default (props) => (
    <StaticQuery
        query={graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "blank-template" } }) {
          frontmatter {
            secondaryIntroduction {
                secIntro
                secSub
                secInfo
            }
          }
        }
      }
    `}
        render={data => <BarHeader data={data} {...props} />}
    />
);