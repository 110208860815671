import styled from "styled-components";

export const LeftPaddedImage = styled.div`
max-width: none;padding-right: 0px;

`
export const RightPaddedImage = styled.div`
max-width: none;padding-left: 0px;

`

export const SpotlightContainer = styled.div`
max-width: none;padding: 2.5vw 0;

`

export const SpotlightImageGroup = styled.div`
max-width: none;padding: 0 15px;

`

export const SpotlightImageContainer = styled.div`
display: block;text-align: center;
`