import styled from 'styled-components';

export const StoryBlockImageContainerRight = styled.div`
           max-width: none;
           padding-right: 0px;
      `

export const StoryBlockImageContainerLeft = styled.div`
           max-width: none;
           padding-left: 0px;
      `