import React, { PureComponent } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { StoryBlockImageContainerLeft, StoryBlockImageContainerRight } from "./StoryBlock.styles.js";

class StoryBlock extends PureComponent { 
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }


  render () {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

      const { data } = this.props;
      const {
          storyBlockSectionTitle,
          storyBlockSectionDescription,
          summaryImage1,
          summaryImage2,
      } = data.markdownRemark.frontmatter.storyBlockSection;
      let summaryImageOne = !!summaryImage1 ? summaryImage1.publicURL : "#";
      let summaryImageTwo = !!summaryImage2 ? summaryImage2.publicURL : "#";

    return (
        <div id="story_block">
            <div className="container">
                <div className="row" style={{marginTop: "5vw"}}>
                    <div className="col-md-6">
                        <h1 data-aos="fade-up" data-aos-duration="500" id="header_one_gray">{storyBlockSectionTitle}<br/></h1>
                    </div>
                    <div className="col-md-6"/>
                </div>
                <div className="row" style={{padding: "5vw 0"}}>
                    <StoryBlockImageContainerRight className="col"><img data-aos="fade-up"
                                                                                          data-aos-duration="750"
                                                                                          id="container_images_compoenent"
                                                                                          src={summaryImageOne}
                                                                                          alt="Summary 1"
                                                                                          width="100%"/></StoryBlockImageContainerRight>
                    <StoryBlockImageContainerLeft className="col"><img data-aos="fade-up"
                                                                                         data-aos-duration="750"
                                                                                         id="container_images_compoenent"
                                                                                         src={summaryImageTwo}
                                                                                         alt="Summary 2"
                                                                                         width="100%"/></StoryBlockImageContainerLeft>
                </div>
                <div className="row" style={{paddingBottom: "10vw"}}>
                    <div className="col">
                        <p data-aos="fade-up" data-aos-duration="600" id="paragraph_black" style={{fontSize: "22px"}}>{storyBlockSectionDescription}<br/></p>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default props => (
    <StaticQuery
        query={graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "blank-template" } }) {
          frontmatter {
              storyBlockSection {
                  storyBlockSectionTitle
                  storyBlockSectionDescription
                  summaryImage1 {
                    publicURL
                   }
                  summaryImage2 {
                    publicURL
                  }
              } 
          }
        }
      }
    `}
        render={data => <StoryBlock data={data} {...props} />}
    />
);