import styled from "styled-components";

export const ContactUsHeading = styled.h1`
  font-size: 1.0em;font-family: 'PT Serif', serif;
`;

export const FormHeading = styled.h1`
font-size: 1.0em;font-family: 'PT Serif', serif;width: 100%;margin: 0 auto;padding-top: 10px;
`;

export const FormGroupParagraph = styled.p`
  font-family: Poppins, sans-serif;
`;

export const FormGroupInput = styled.input`
margin: 0 0 30px;background-color: rgb(240,244,247);border: none;
`;

export const FormGroupSelect = styled.select`
margin: 30px 0;background-color: rgb(240,244,247);border: none;
`;

export const FormGroupInputVersion1 = styled.input`
margin: 30px 0;background-color: rgb(240,244,247);border: none;
`;

export const FormGroupTextArea = styled.textarea`
margin: 30px 0;background-color: rgb(240,244,247);border: none;height: 150px;
`;


export const FormGroupButton = styled.button`
background-color: #fe6273 !important;
margin: 20px !important;
box-shadow: 0 0 0 .2rem #fe6273 !important;
color: #fff !important;
border: 1px solid transparent !important;
padding: .375rem .75rem !important;
font-size: 1rem !important;
line-height: 1.5 !important;
border-radius: .25rem !important;
transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
`;

export const FormGroupItal = styled.i`
background-color: #eaeaea;
box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
`;

export const FormDescriptionHeader = styled.div`
  display: block;
  @media(max-width: 767px) {
    display: none;
  }
`

export const FormSuccessContainer = styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
       font-size: 2.0em;
       color: white;

`