import React, { PureComponent } from 'react';
import { TextHeaderGroup } from './MapBlock.styles.js'
import {graphql, StaticQuery} from "gatsby";

class MapBlock extends PureComponent { 
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  render () {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    const { data } = this.props;
    const {
        mapTitle,
        mapDescription,
        mapLink,
        mapImage,
      } = data.markdownRemark.frontmatter.mapBlockSection;

    return (
        <div id="map_block" style={{backgroundColor: "#FAFAFA"}}>
            <div className="container" style={{backgroundColor: "#FAFAFA"}}>
                <div className="row" style={{padding: "100px 0"}}>
                    <div className="col-md-6" style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                        <TextHeaderGroup>
                            <h1 data-aos="fade-up" id="header_one_white" style={{color: "#1f1f1f"}}>{mapTitle}<br/>
                            </h1>
                            <p data-aos="fade-up" data-aos-delay="250" id="paragraph_black" style={{letterSpacing: "normal",
                                fontSize: "1.2em"}}>{mapDescription}<br/></p><a data-aos="fade-up" data-aos-delay="300" id="link_black" target="_blank" rel="noopener noreferrer"
                                                           href={mapLink}>Come Visit!</a></TextHeaderGroup>
                    </div>
                    <div className="col-md-6" style={{textAlign: "center"}}><img data-aos="fade-left"
                                                                               data-aos-duration="250"
                                                                               src={mapImage.publicURL}
                                                                               alt="Map"
                                                                               width="100%"/></div>
                </div>
            </div>
        </div>
    );
  }
}

export default props => (
    <StaticQuery
        query={graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "blank-template" } }) {
          frontmatter {
              mapBlockSection {
                mapTitle
                mapDescription
                mapLink
                mapImage {
                    publicURL
                }
              } 
          }
        }
      }
    `}
        render={data => <MapBlock data={data} {...props} />}
    />
);