import styled from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll'

export const IntroMantra = styled.p`
  font-family: 'Work Sans', sans-serif;
  color: #003adb;font-weight: 300;
  line-height: 1.61111;
  margin: 60px 0;
  text-align: center;
  font-size: 1em;
`;

export const ContainerComp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10vw 0 0 0;
`;

export const LinkHandler = styled(AnchorLink)`
  color: #fff !important;
  height: 50px;
  margin-right: 5vw;
  width: 50px;
  border-radius: 25px !important;
  border-width: 0.5px;
  position: relative;
  background-color: #ffffff;
  border-color: #b7b7b7;
  box-shadow: none !important;
`;

export const Chevron = styled.i`
  color: #4e4e4e;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;