import React, { PureComponent } from 'react';
import styled from 'styled-components';
import raw_bar_menu from '../../img/Menu_Showcase.png';

const VerticalComp = styled.div`
visibility: inherit;opacity: 1;transform: matrix(1, 0, 0, 1, 0, 0);align-items: flex-start;position: relative;top: 10em;padding-left: 15px;padding-right: 15px;position: -webkit-sticky;position: sticky;
`;


class RawBarBlock extends PureComponent { 
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  render () {
    if (this.state.hasError) {
      return <h1>Overdue</h1>;
    }
    return (
<div className="RawBarBlockWrapper">
<div id="raw_bar" style={{height: 'auto', backgroundColor: '#232328'}}>
  <div className="container" style={{padding: '10vw 0vw'}}>
      <div className="row" id="showcase">
          <div className="col-md-6">
              <div style={{textAlign: 'center'}}><img id="Menu_Showcase_img" src={raw_bar_menu} alt="Raw Bar Menu" height="100%" width="85%"/></div>
          </div>
          <div className="col-md-6">
              <VerticalComp>
                  <h1 data-aos="fade-up" data-aos-duration="500" id="header_one_white">Lean back, enjoy</h1>
                  <h1 data-aos="fade-up" data-aos-duration="750" id="header_one_gray">Front row access to the action</h1>
                  <p data-aos="fade-up" data-aos-duration="1000" id="paragraph_white">We are &nbsp;a casual East Village neighborhood spot with heart and soul, serving seasonal American food in a welcoming, relaxed setting. Opened by Jake and his team in 2016, Brix+Brine was created to serve as an all-day gathering
                      place for neighbors and visitors alike. The restaurant is open monday thru saturday from 11 to 11. It's that simple.<br/></p>
              </VerticalComp>
          </div>
      </div>
  </div>
</div>
</div>
    );
  }
}

RawBarBlock.propTypes = {
  // bla: PropTypes.string,
};

RawBarBlock.defaultProps = {
  // bla: 'test',
};

export default RawBarBlock;
