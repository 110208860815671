import React from 'react';
import { FormGroupButton, ContactUsHeading, FormGroupInput, FormGroupInputVersion1,
    FormGroupParagraph , FormGroupSelect, FormGroupTextArea , FormHeading, FormGroupItal, FormDescriptionHeader,
    FormSuccessContainer
} from "./ContactBlock.styles";

function encode(data) {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}


export default function Contact(props) {
    const [state, setState] = React.useState({})
    let success = false
    let successCompletionEvent = false

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target

        if(handleValidation()) {
            // Submit Form
            fetch('/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: encode({
                    'form-name': form.getAttribute('name'),
                    ...state,
                }),
            })
                .then(() => handleAnimation())
                .catch((error) => alert(error))
        }
    }

    const handleAnimation = () => {
        alert("Form Submitted Successfully, now refreshing")
        window.location.reload()
    }

    const handleValidation = () => {
        let formIsValid = true;
        const holder = {...state}

        /// Form Is Empty
        if(JSON.stringify(holder) === '{}') {
            alert("One or more fields are not valid")
            return false
        }

        if(!holder["Full Name"] || !holder["Email"] || !holder["Type Of Message"] || !holder["Contact Number"] ||  !holder["User Message"]) {
            alert("One or more fields are not valid")
            return false
        }

        // Full Name
        if(typeof holder["Full Name"] !== "undefined"){
            if(!holder["Full Name"].match(/^[\w'\-,.]*[^_!¡?÷¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]*$/)){
                alert("Invalid Name")
                return false;
            }
        }

        // Email
        if(typeof holder["Email"] !== "undefined"){
            let lastAtPos = holder["Email"].lastIndexOf('@');
            let lastDotPos = holder["Email"].lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && holder["Email"].indexOf('@@') === -1 && lastDotPos > 2 && (holder["Email"].length - lastDotPos) > 2)) {
                alert("Invalid Email")
                return false;
            }
        }

        // Contact #
        if(typeof holder["Contact Number"] !== "undefined"){
            if(!holder["Contact Number"].match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im)){
                alert("Invalid Phone Number")
                return false;
            }
        }
        return formIsValid;
    }

    const { data } = props;
    const {
        contactUsTitle,
        contactUsSubTitle,
        contactUsSecondaryTitle,
        contactUsSecondarySubTitle,
        contactUsSecondaryEmail,
        contactUsImage,
    } = data.markdownRemark.frontmatter.contactBlockSection;

    return (
        <div id="Contact_sectional">
            { successCompletionEvent ?
                <div className="scale-in-center">
                    { success ?
                        <FormSuccessContainer className="color-change-3x" style={{height: "30vh"}}>
                            <div className="RestaurantBlockstyles__RestaurantBlockContainer6"> Your Message Has Been Sent <span role="img" aria-label="Airplane">✈️</span></div>
                        </FormSuccessContainer> : null
                    }
                </div>
                :
                <div className="container" style={{backgroundColor: '#fff', color: '#1f1f1f'}}>
                    <div className="row" style={{paddingTop: '10vw', paddingBottom: '10vw'}}>
                        <div className="col">
                            <ContactUsHeading data-aos="fade-up" data-aos-delay="50" className="text-center">{contactUsTitle}</ContactUsHeading>
                            <h1 data-aos="fade-up" data-aos-delay="200" className="text-center" id="contact_us_header">{contactUsSubTitle}<br/></h1>
                        </div>
                    </div>
                    <div className="row" style={{height: 'auto', paddingBottom: '5vw'}}>
                        <FormDescriptionHeader className="col">
                            <h1 className="text-left" id="contact_us_secondary_header" data-aos="fade-up" data-aos-delay="225" style={{textAlign: 'center'}}>{contactUsSecondaryTitle}<br/></h1>
                            <FormHeading className="text-left" data-aos="fade-up" data-aos-delay="250">{contactUsSecondarySubTitle}<a href="mailto:jake@brixbrine.com?subject=Hi Brix+Brine!%20%E2%9C%8B" style={{color: '#8e9396'}}>{contactUsSecondaryEmail}</a><br/></FormHeading>
                            <div style={{paddingTop: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><img data-aos="fade-up" data-aos-delay="300" alt="side piece" src={contactUsImage.publicURL} style={{width: '50%'}}/></div>
                        </FormDescriptionHeader>
                        <div className="col-md-6 col-xl-5">
                            <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field" action="/" onSubmit={handleSubmit}>
                                <input type="hidden" name="form-name" value="contact"/>
                                <FormGroupParagraph>Full name *</FormGroupParagraph>
                                <FormGroupInput className="form-control" type="text" required="" placeholder="John Doe" onChange={handleChange} name="Full Name"/>
                                <FormGroupParagraph>Email&nbsp;*</FormGroupParagraph>
                                <FormGroupInput className="form-control" type="text" required="" placeholder="hi@brixbrine.com" onChange={handleChange} name="Email"/>
                                <FormGroupParagraph>Subject *</FormGroupParagraph><FormGroupSelect className="form-control" onChange={handleChange} name="Type Of Message" defaultValue={"disabled"}><option value="disabled" disabled>Please Select</option><option value="Reservation">Reservation</option><option value="Private Event">Private Event</option><option value="Other">Other</option></FormGroupSelect>
                                <FormGroupParagraph>Phone number *</FormGroupParagraph>
                                <FormGroupInputVersion1 className="form-control" type="tel" placeholder="(207)-641-2971" required="" minlength="9" maxlength="13" inputmode="tel" onChange={handleChange} name="Contact Number"/>
                                <FormGroupParagraph>Message &nbsp;<FormGroupItal className="fa fa-paperclip"/>&nbsp;*</FormGroupParagraph><FormGroupTextArea className="form-control" required="" placeholder="Write your message here." minlength="10" onChange={handleChange} name="User Message"/>
                                <FormGroupButton className="btn btn-primary" type="submit">Contact us</FormGroupButton>
                            </form>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

