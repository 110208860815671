import React, { PureComponent } from 'react';
import { RestaurantBlockContainer, RestaurantBlockContainer6, RestaurantBlockContainer1} from './RestaurantBlock.styles';
import {graphql, StaticQuery} from "gatsby";

class RestaurantBlock extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  render () {

      const { data } = this.props;
      const {
          sisterRestaurantTitle,
          sisterRestaurantDescription,
          sisterRestaurantLogo,
      } = data.markdownRemark.frontmatter.sisterRestaurantBlockSection;
      const {
          crookedpine,
      } = data.markdownRemark.frontmatter.socialLinks;
    if (this.state.hasError) {
      return <h1>You Win a prize!!</h1>;
    }
    return (
        <div id="sister_block" className="re" style={{padding: '10vw 0'}}>
          <div className="container">
              <div className="row">
                  <div className="col-md-6">
                      <RestaurantBlockContainer>
                          <RestaurantBlockContainer6 data-aos="fade-right" data-aos-delay="250">{sisterRestaurantTitle}</RestaurantBlockContainer6>
                          <RestaurantBlockContainer1 data-aos="fade-right" data-aos-delay="500">{sisterRestaurantDescription}<br/></RestaurantBlockContainer1>
                      </RestaurantBlockContainer>
                  </div>
                  <div className="col-md-6" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><a data-aos="fade-left" data-aos-delay="250" href={crookedpine} style={{display: 'flex', justifyContent:'center', alignItems: 'center'}}><img src={sisterRestaurantLogo.publicURL} alt="brix" width="40%"/></a></div>
              </div>
          </div>
        </div>
    );
  }
}

export default props => (
    <StaticQuery
        query={graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "blank-template" } }) {
          frontmatter {
              sisterRestaurantBlockSection {
                sisterRestaurantTitle
                sisterRestaurantDescription
                sisterRestaurantLogo {
                    publicURL
                }
              } 
               socialLinks {
                crookedpine
               }
          }
        }
      }
    `}
        render={data => <RestaurantBlock data={data} {...props} />}
    />
);

