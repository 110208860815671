import styled from "styled-components";

// export const VideoContainer = styled.div`
//   text-align: center;
//   z-index: 10;
//   position: absolute;
//   top: 40%;
//   left: 0;
//   width: 100%;
// `;

// export const ButtonContainer = styled.button`
//   background-color: rgba(250, 250, 250, 0.32);
//   border: none;
//   height: 60px;
//   border-radius: 25px;
//   width: 110px;
// `;


export const IntroImageContainer = styled.div`
    display: block;
    text-align: center;
`



